import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import logo from "../images/botEcosystem.jpg"; // Logo dosyanızın yolu
import LaunchpadImage from '../images/launchpad.jpg';
import "../style/forum.css";



function CryptoTokenForm (){
  const navigate = useNavigate();
  
  const [name, setName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [totalSupply, setTotalSupply] = useState(0);

  const [decimal, setDecimal] = useState(0);
  const [initialLp, setInitialLp] = useState(0);
  const [liquidityLock, setLiquidityLock] = useState(0);
  const [ownerWallet, setOwnerWallet] = useState('');
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [telegramAddress, setTelegramAddress] = useState("");
  const [scrolling, setScrolling] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  const handleSubmit = (e) => {
    e.preventDefault();
    // Form gönderim işlemleri burada yapılabilir
    const formData = {
      name,
      symbol,
      totalSupply,
      decimal,
      initialLp,
      liquidityLock,
      ownerWallet,
      telegramAddress
    };
    // Bytecode ve ABI bilgilerini elde etme

    console.log('Gönderilen veri:', formData);
    navigate('/deploy', { state: { formData } });
    // Veriyi başka bir yere göndermek için burada işlemleri yapabilirsiniz.
  };

  return (
    <div className='row'>
      
    <header className={`header ${scrolling ? 'scrolled' : ''}`}>
        <div className="logo-container">
          <Link to="/">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
        </div>
        {/* Mobile Menu Toggle Button */}
        <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
          ☰
        </button>
        {/* Regular Menu */}
        <div className="menu">
          <button onClick={() => navigate('/')}>Home</button>
          <button onClick={() => navigate('../deployform')}>Deploy</button>
        </div>
        {/* Mobile Menu */}
        <div className={`menu mobile-menu ${isMobileMenuOpen ? 'active' : ''}`}>
          <button onClick={() => { setMobileMenuOpen(false); navigate('/'); }}>Home</button>
          <button onClick={() => { setMobileMenuOpen(false); navigate('../deployform'); }}>Deploy</button>
        </div>
      </header>
      <div className='contain'>
        <img className='launchpadimg' src={LaunchpadImage} alt="Launchpad" />
        <p>🟢 Coffe LaunchPad: <a href="https://t.me/CoffeeLaunchPad" target="_blank" rel="noopener noreferrer">https://t.me/CoffeeLaunchPad</a></p>
        
      <div id="telegram-link">
        <p><b>This is an example.</b></p>
    🆔 Telegram link: <a href="https://t.me/coffeerium" target="_blank" rel="noopener noreferrer">https://t.me/coffeerium</a>
  </div>

  <div id="contract-info">
    <p>New Contract Deployed!</p>
    <p>🆔 Deployer Username: </p>
    <p>🆔 Deployer ID: </p>
    <p>🔠 Token Name: Coffeerium </p>
    <p>#️⃣ Contract 0x…</p>
    <p>➗ Buy Tax: 5.0</p>
    <p>➗ Sell Tax: 5.0</p>
    <p>💰 Initial LP: 2.0 Bnb</p>
    <p>🔐 Liquidity lock time: 365 days</p>
    <p>💰 Max Transaction: %2.0</p>
    <p>💰 Max Wallet: %2.0</p>
    <p>🆔 Website link: <a href="http://coffeerium.net/" target="_blank" rel="noopener noreferrer">http://coffeerium.net/</a></p>
  </div>
      </div>
    <div className="container">
  
    <div className="App">
      <h2>Crypto Token Information</h2>

      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        <label htmlFor="symbol">Symbol:</label>
        <input
          type="text"
          id="symbol"
          name="symbol"
          value={symbol}
          onChange={(e) => setSymbol(e.target.value)}
          required
        />

        <label htmlFor="totalSupply">Total Supply:</label>
        <input
          type="number"
          id="totalSupply"
          name="totalSupply"
          value={totalSupply}
          onChange={(e) => {
            // Check if the entered value is a number
            const numericValue = e.target.value;
            if (!isNaN(numericValue)) {
              setTotalSupply(numericValue);
            }
          }}
          required
        />
        
        <label htmlFor="decimal">Decimal :</label>
        <input
          type="number"
          id="decimal"
          name="decimal"
          value={decimal}
          onChange={(e) => {
            // Check if the entered value is a number
            const numericValue = e.target.value;
            if (!isNaN(numericValue)) {
              setDecimal(numericValue);
            }
          }}
          required
        />
        <label htmlFor="initialLp">Initial LP :</label>
        <input
          type="number"
          id="initialLp"
          name="initialLp"
          value={initialLp}
          onChange={(e) => {
            // Check if the entered value is a number
            const numericValue = e.target.value;
            if (!isNaN(numericValue)) {
              setInitialLp(numericValue);
            }
          }}
          required
        />
        <label htmlFor="liquidityLock">Liquidity Lock Time (in Days) :</label>
        <input
          type="number"
          id="liquidityLock"
          name="liquidityLock"
          value={liquidityLock}
          onChange={(e) => {
            // Check if the entered value is a number
            const numericValue = e.target.value;
            if (!isNaN(numericValue)) {
              setLiquidityLock(numericValue);
            }
          }}
          required
        />
        <label htmlFor="ownerWallet">Owner Wallet :</label>
        <input
          type="text"
          id="ownerWallet"
          name="ownerWallet"
          value={ownerWallet}
          onChange={(e) => setOwnerWallet(e.target.value)}
        />
        
        <label htmlFor="telegramAddress">Telegram Address :</label>
        <input
          type="text"
          id="telegramAddress"
          name="telegramAddress"
          value={telegramAddress}
          onChange={(e) => setTelegramAddress(e.target.value)}
        />
        

        <br />
        

        <button type="submit">Submit</button>
      </form>
    </div>
  </div>
    <div className='contain'>
      <img className='launchpadimg' src={LaunchpadImage} alt="Launchpad" />
    <div>
      <p>🟢 Coffee Calls Listing: <a href="https://t.me/Coffeepartnerships" target="_blank" rel="noopener noreferrer">https://t.me/Coffeepartnerships</a></p>
          
      <p><b>✨ Shining Stars with the Coffee Assurance ☕🚀</b></p>
          
      <p>Discover the rising advertising stars under the Coffee Certification! The names on this phenomenal list are revolutionizing the world of advertising on YouTube, Telegram, Twitter, and other social media platforms.</p>
          
      <ul>
        <li>🌟 They stand out with their creativity and engagement.</li>
        <li>🚀 Unleashing boundless creativity in advertising, fueled by the unique energy of coffee.</li>
        <li>☕ The epitome of trust: Coffee Assurance propels them ahead of the competition.</li>
      </ul>
          
      <p>Get ready, because these names, backed by the assurance of coffee, will bring a whole new flavor to the advertising world! ✨👏</p>
  </div>
    </div>

    </div>
  );
}

export default CryptoTokenForm;

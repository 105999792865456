import React, { useState,useEffect } from 'react';
import "../style/homepage.css";
import { useNavigate, Link  } from 'react-router-dom';
import logo from "../images/botEcosystem.jpg"; // Logo dosyanızın yolu

function HomePage() {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`homepage ${scrolling ? 'scrolled' : ''}`}>
      {/* Header */}
      <header className={`header ${scrolling ? 'scrolled' : ''}`}>
        <div className="logo-container">
          <Link to="/">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
        </div>
        {/* Mobile Menu Toggle Button */}
        <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
          ☰
        </button>
        {/* Regular Menu */}
        <div className="menu">
          <button onClick={() => scrollToSection('welcome')}>Home</button>
          <button onClick={() => navigate('deployform')}>Deploy</button>
          <button onClick={() => scrollToSection('about')}>About Us</button>
          <button onClick={() => scrollToSection('projects')}>Our Projects</button>
          <button onClick={() => scrollToSection('contact')}>Help Desk</button>
        </div>
        {/* Mobile Menu */}
        <div className={`menu mobile-menu ${isMobileMenuOpen ? 'active' : ''}`}>
          <button onClick={() => { setMobileMenuOpen(false); scrollToSection('welcome'); }}>Home</button>
          <button onClick={() => { setMobileMenuOpen(false); navigate('deployform'); }}>Deploy</button>
          <button onClick={() => { setMobileMenuOpen(false); scrollToSection('about'); }}>About Us</button>
          <button onClick={() => { setMobileMenuOpen(false); scrollToSection('projects'); }}>Our Projects</button>
          <button onClick={() => { setMobileMenuOpen(false); scrollToSection('contact'); }}>Help Desk</button>
        </div>
      </header>

      {/* Content */}
      <div className='mid-container'>
      <div className="content">
      <div id="welcome">
        <h2>Welcome</h2>
        <p>Welcome to our website!</p>
        <p>
          "Coffee Certificate" smart contracts have rendered options such as Scam, rugpull, tax escalation, reduction of the maximum sales limit, and sales prohibition completely ineffective to mitigate potential risks.</p>
          <p>This measure has been meticulously implemented not only to safeguard investors but also to build a reliable investor community.
        </p>
        <p>
          The security and satisfaction of our investors are our top priorities.
        </p>
      </div>

      <div id="about">

        <h2>Coffee Advantages</h2>
        <p>
        <b>Contract Creation Process : </b>
        Are you ready to experience the excitement of creating a new smart contract? Now, under the Coffee Certificate, create your own contract and automatically announce this tremendous achievement in our @CoffeeLaunchpad group! This group is the perfect place for sharing the details of your contract.
        </p>
        <p>
        <b>Advertising Collaborations and Assurance:</b>
        Furthermore, if you wish to promote your project under the security of the Coffee Certificate, check out our @CoffeePartnerships group. While you may not be able to share your projects directly, you can collaborate with our affiliated advertisers to broadcast your projects to everyone. Together, we are achieving greater successes!
        </p>
        <p>
        Let's embark on a journey filled with the aroma of coffee-scented successes!
        </p>
      </div>


      <div id="about">
        <h2>About Us</h2>
        <p>
          We are a company specializing in the development of Telegram bots and utility programs in the cryptocurrency domain.
          Several examples of our services are available on our website. Explore our bots and join our Telegram groups by clicking the links below:
        </p>
        <p>
          Discover the latest developments in the crypto world by utilizing our bots in our Telegram groups. We look forward to having you with us!
        </p>
      </div>

        <h2>Our Projects</h2>
        <div id="projects">
        <div className="project">
            <a href='https://t.me/Biggestbuybot' target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="Project 1" />
            <p>Biggest Buy Bot</p>
            </a>
          </div>
          <div className="project">
            <a href='https://t.me/Scantechbot' target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="Project 1" />
            <p>Scan Tech Bot</p>
            </a>
          </div>
          <div className="project">
            <a href='https://t.me/Pricetechbot' target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="Project 1" />
            <p>Price Tech Bot</p>
            </a>
          </div>
          
          <div className="project">
            <a href='https://t.me/Eventstechbot' target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="Project 1" />
            <p>Events Tech Bot</p>
            </a>
          </div>
          
          <div className="project">
            <a href='https://t.me/Signariumbot' target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="Project 1" />
            <p>Signarium Bot</p>
            </a>
          </div>   
        </div>
        <h2>Help Desk Contact</h2>
<div id="contact">
  <p>
    Coffee Certificate Smart Contract Management and Support
    <br /><br />
    If you're looking to obtain the certificate of the smart contract you deployed under the name Coffee Certificate, follow these steps for a smooth process:
    <ol>
      <li>Liquidity Lock: Lock liquidity for a minimum of 10 days to initiate the certificate issuance process.</li>
      <li>Submit Lock Link: After completing the liquidity lock, submit the lock link to administrators securely through the help and support section.</li>
      <li>Issue Resolution: For any issues encountered during this process or general problem-solving, use the same communication channel to reach out to our support team.</li>
    </ol>
    We're here to assist you at every step of obtaining your certificate. Feel free to reach out with any questions or concerns!
  </p>
  <b className='contactcolor'>Contact : <a href='https://t.me/botecosystem' target="_blank" rel="noopener noreferrer">Bot Ecosystem</a></b>
</div>

      </div>
      </div>
    </div>
  );
}

export default HomePage;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import CryptoTokenForm from "./page/CryptoTokenForm.js"
import HomePage from "./page/HomePage.js"
import{
	createBrowserRouter,
	RouterProvider
} from "react-router-dom";

const router=createBrowserRouter([
	{
		path:"/",
		element:<HomePage/>,
	},	
	{
		path:"deployform",
		element:<CryptoTokenForm/>,
	},
	{
		path:"deploy",
		element:<App/>,
	},

]);
	
const root=ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<RouterProvider router={router}/>
);

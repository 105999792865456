import { useState,useEffect } from "react";
import {ethers} from "ethers";
import {lock_address} from "../constants/adresses";
import {lock_abi} from "../constants/abi";

export const useLockContract=()=>{
 const [contract, setContract]=useState(null);
 
 useEffect(()=>{
    const provider=new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const _contract=new ethers.Contract(lock_address, lock_abi, signer);
    setContract(_contract);
 }, []);
 return contract;
};